import React from 'react'
import Maps from './Maps'

const ChargerMap = () => {
  return (
    <div>
      ChargerMap
      <Maps/>
    </div>
  )
}

export default ChargerMap
